import React, { useState, useEffect } from 'react';

import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';
import { onAuthStateChanged } from "firebase/auth";

import { auth } from '../firebaseConfig/firebase';
import { signOut } from "firebase/auth";

import Login from './Login';


// EXPORTAR ERRORES EN FORMATO JSON POR CONSOLE LOG
function GetInfo() {

    const [valido, setValido] = useState();
    const [lista1, setLista] = useState([]);

    // AUTHENTICACIÓN
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {

                setValido(true);

            } else {
                setValido(false);
                console.log("user is logged out")

            }
        });

    }, [])

    useEffect(() => {
        const getLista = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'Errors'))
                const docs = []

                querySnapshot.forEach((doc) => {
                    //const body = doc.data().options['body']
                    const loc = doc.data().loc
                    const id = doc.data().id
                    //var info = body + ','
                
                    docs.unshift({loc, id})

                })
                
                setLista(docs);
                console.log(lista1)

            } catch (error) {
                console.log(error);
            }
        }
        getLista()
    }, [lista1]);

    if (valido) return (
       <p></p>
    )


    return (
        <Login />
    )
}

export default GetInfo