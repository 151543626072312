import React, { useState, useEffect } from 'react';

import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import Login from './Login';

import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebaseConfig/firebase';
import { doc, setDoc, collection, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';

import DatosImportar from '../import_ff.json';
import DatosImportar1 from '../import_test.json';


// IMPORTAR ERROR LEADS A FIREBASE DESDE JSON
function Upload3() {

    const [valido, setValido] = useState();
    const [importOk, setImportOk] = useState();
    const [count, setCount] = useState(0);


    const schema = yup.object().shape({
        nombre: yup.string().min(6,),

    });


    // CREAR DOCUMENTO FIREBASE
    const enviar = async (values) => {
        var date = new Date().getTime();
        date = date.toString();

        console.log(values);


        DatosImportar.forEach((i) => {
            const nombre = i.nombre
            const primer_apellido = i.primer_apellido
            const segundo_apellido = i.segundo_apellido
            const email = i.email
            const prefix = i.prefix
            const tel = i.tel
            const lenguage = i.lenguage
            const course = i.course
            const bday = i.bday
            const type_id = i.type_id
            const gender = i.gender
            const alias = i.alias
            const idNumber = i.idNumber
            const cp_nat = i.cp_nat
            const cp_res = i.cp_res
            const address = i.address 
            const idMunicipio_nat = i.idMunicipio_nat
            const pais_res = i.pais_res
            const state_res = i.state_res
            const city_res = i.city_res
            const idMunicipio_res = i.idMunicipio_res
            const pais_res_id = i.pais_res_id
            const nationality_id = i.nationality_id
            const nationality = i.nationality
            const pais_nat = i.pais_nat
            const provinceNatId = i.provinceNatId
            const pais_nat_id = i.pais_nat_id
            const nombreMunicipio_nat = i.nombreMunicipio_nat
            const certificat_empadronament = i.certificat_empadronament
            const IBAN = i.IBAN
            const terms = i.terms
            const declaracio = i.declaracio
            const via_acceso_lead = i.via_acceso_lead

            console.log(nombre,	primer_apellido,	segundo_apellido,	email,	prefix,	tel,	lenguage,	course,	bday,	type_id,	gender,	alias,	idNumber,	cp_nat,	cp_res,	address,	idMunicipio_nat,	pais_res,	state_res,	city_res,	idMunicipio_res,	pais_res_id,	nationality_id,	nationality,	pais_nat,	provinceNatId,	pais_nat_id,	nombreMunicipio_nat,	certificat_empadronament,	IBAN,	terms,	declaracio,	via_acceso_lead)
                

            try {
                 setDoc(doc(db, 'ng_Opportunity', i.email), {
                    nombre,	primer_apellido,	segundo_apellido,	email,	prefix,	tel,	lenguage,	course,	bday,	type_id,	gender,	alias,	idNumber,	cp_nat,	cp_res,	address,	idMunicipio_nat,	pais_res,	state_res,	city_res,	idMunicipio_res,	pais_res_id,	nationality_id,	nationality,	pais_nat,	provinceNatId,	pais_nat_id,	nombreMunicipio_nat,	certificat_empadronament,	IBAN,	terms,	declaracio,	via_acceso_lead

                })
                console.log("Sended");
    
            } catch (error) {
                console.log(error);
            }



        })

    }


    // AUTHENTICACIÓN
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {

                setValido(true);

            } else {
                setValido(false);
                console.log("user is logged out")

            }
        });

    }, [])



    if (valido) return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={enviar}
                initialValues={{
                    nombre: ''
                }}
                validateOnMont>

                {({ handleSubmit, handleChange, values, errors, isValid }) => (

                    <Form className="p-3" noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="nombre" className="mb-4">

                            <Form.Label className="form-label mb-0">
                                <h2>CREAR LEADS A LA FIREBASE</h2>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={20}
                                name="nombre"
                                className="form-control border-dark ps-1 pb-1 fw-bold"
                                value={values.nombre}
                                onChange={handleChange}

                            />
                        </Form.Group>



                        <Button
                            variant="dark"
                            type="submit"
                            value="Enviar"
                            as="input"
                            disabled={!isValid}
                        //onClick={() => { handleClose(); }}
                        >
                        </Button>
                    </Form>
                )}

            </Formik>
            <p>{importOk}</p>
            <p>{count}</p>



        </>
    )

    return (
        <Login />
    )

}

export default Upload3