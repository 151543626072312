import React, { useState, useEffect } from 'react';

import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import Login from './Login';

import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebaseConfig/firebase';
import { doc, setDoc, collection, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';


function Upload2() {

    const [valido, setValido] = useState();
    const [importOk, setImportOk] = useState();
    const [count, setCount] = useState(0);

    const schema = yup.object().shape({
        nombre: yup.string().required().min(6,),

    });


    // ENVIAR CORREO FILMIN
    const sendEmail = async (nombre, mailEscac, codigo) => {

        try {
            const collectionRef = collection(db, 'email');
            const emailContent = {
                to: mailEscac,
                message: {
                    subject: `Código Filmin`,
                    html: `                      
                        <body>
                            <div style="width: 70%; max-width: 700px; margin: auto;">
                                <div style="padding-left: 20px; padding-right: 20px; font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">
                                    <div style="padding-top: 20px;">
                                        <p>Hola ${nombre}</p>
                                        <p>Ya tienes a tu disposición el código de acceso al espacio de PANTALLA ESCAC en Filmin, donde encontrarás la mayor parte de la filmografía obligatoria de las asignaturas teóricas de tu curso</p>
                                        <p>Las instrucciones para activarlo son las siguientes: </p>

                                        <p>1. Los detalles de acceso al espacio ESCAC en Filmin son:</p>

                                        <p style="margin-top: 10px; text-align: justify;">
                                            <strong>Alumno</strong> : ${nombre} </br>
                                            <strong>Código Filmin</strong>: ${codigo}
                                         </p>
                                         <p>2. Entra en: <a>href=https://www.filmin.es/codigo>https://www.filmin.es/codigo</a></p>
                                        <p>3. Introduce tu código en la cajetilla vacía y clica en el botón "Canjear código"</p>
                                        <p>4. Te aparecerá directamente la página de Registro de filmin. Aquellos de vosotros que ya tengáis cuenta en filmin, solo tendréis que entrar a través de vuestra cuenta. Los que no, tendréis que registraros; es un minuto: necesitaréis solo poner nombre de usuario (puede ser vuestro mail) y una contraseña </p>
                                        <p>5. Una vez hayas entrado con tu cuenta de filmin, ya podrás ver las películas en el espacio PANTALLA ESCAC de tu curso mediante este link: https://www.filmin.es/pack/escac-2</p>
                                        <p>6. IMPORTANTE: Tu código de filmin es personal e intransferible. Una vez te lo hemos dado, la escuela ya no se hace responsable del mismo, es decir, si se lo das a otra persona o lo extravías, será responsabilidad tuya y no se podrá sustituir por otro.</p>
                                        <p>Es MUY importante que hagas estos trámites rápidamente, porque los códigos tienen una caducidad. Si no se activan en un plazo determinado, ya no podrás activarlo. </p>

                                        <p>Tienes una suscripción de un año desde el momento en que actives el código</p>

                                        
                        
                                    </div>
                                    
                                    <div style="margin-top: 30px;">
                                        <p style="margin-top: 5px;">Un saludo y muchas gracias.</p>
                                    </div>
                                </div>
                            </div>`,
                }
            }
            var date = new Date().getTime();
            var date = date.toString();
            await setDoc(doc(db, 'email', date), emailContent);
            //addDoc(collectionRef, emailContent);
            console.log("Email Sended");
            setImportOk("¡Correos enviados!");
            setCount(count + 1);


        } catch (error) {
            console.log(error);
        }

    }

    function envioCorreos(info, infoLength, x){
        console.log(info, infoLength)
        if (x < infoLength) {
            setTimeout(() => envioCorreos(info, infoLength, x+1), 5000)
            const nombre = info[x].nombre;
            const mail = info[x].mailEscac;
            const codigo = info[x].codigo;

            sendEmail(nombre, mail, codigo);

        }
        
    }


    // CREAR DOCUMENTO FIREBASE
    const enviar = async (values) => {
        var date = new Date().getTime();
        date = date.toString();

        try {
            await setDoc(doc(db, 'Correos', date), {
                ...values
            })
            console.log("Sended");

            const docs = []
            const body = JSON.parse(values.nombre)
            docs.unshift(body)

            const info = docs[0];
            const infoLength = docs[0].length;

            envioCorreos(info, infoLength, 0)
            /*docs[0].map(i => {
                //setTimeout(sendEmail(i.nombre, i.mailEscac, i.mailPersonal), 3000);
                //setTimeout(sendEmail, 30000, i.nombre, i.mailEscac, i.codigo)
            })*/

        } catch (error) {
            console.log(error);
        }
    }


    // AUTHENTICACIÓN
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {

                setValido(true);

            } else {
                setValido(false);
                console.log("user is logged out")

            }
        });

    }, [])



    if (valido) return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={enviar}
                initialValues={{
                    nombre: ''
                }}
                validateOnMont>

                {({ handleSubmit, handleChange, values, errors, isValid }) => (

                    <Form className="p-3" noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="nombre" className="mb-4">

                            <Form.Label className="form-label mb-0">
                                <h2>ENVIAR CORREU DE FILMIN</h2>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={20}
                                name="nombre"
                                className="form-control border-dark ps-1 pb-1 fw-bold"
                                value={values.nombre}
                                onChange={handleChange}

                            />
                        </Form.Group>



                        <Button
                            variant="dark"
                            type="submit"
                            value="Enviar"
                            as="input"
                            disabled={!isValid}
                        //onClick={() => { handleClose(); }}
                        >
                        </Button>
                    </Form>
                )}

            </Formik>
            <p>{importOk}</p>
            <p>{count}</p>



        </>
    )

    return (
        <Login />
    )

}

export default Upload2