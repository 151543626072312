import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';
import { Row, Col, Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import imagen from '../giphy.gif';
import imagen2 from '../cuack.gif';



function Show() {

  const [lista1, setLista] = useState([]);
  const [listaErrors, setListaErrors] = useState([]);

  /* const [error, setError] = useState ( [] );

   const errorsCollection = collection(db, "Errors");

   const getErrors = async () => {
       const data = await getDocs(errorsCollection);
       console.log(data.docs);

       /*setError(
           data.docs.map((doc) => ({...doc.data(), id:doc.id}))
       )

       console.log(error);
   }*/


  // Llista d'errors per tornar a intentar pujar-los
  useEffect(() => {
    const getListaErrors = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Errors'))
        const docs = []

        querySnapshot.forEach((doc) => {
          //const body = doc.data().options['body']
          const loc = doc.data().loc
          const id = doc.data().id
          //var info = body + ','

          docs.unshift({ loc, id })

        })

        setListaErrors(docs);
        //console.log(listaErrors)

      } catch (error) {
        console.log(error);
      }
    }
    getListaErrors()
  }, [listaErrors]);

  useEffect(() => {
    const getLista = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'Errors'))
        const docs = []

        querySnapshot.forEach((doc) => {
          const body = JSON.parse(doc.data().options['body'])
          const timestamp = parseInt(doc.data().id, 10);

          const date = new Date(timestamp);

          const yyyy = date.getFullYear();
          let mm = date.getMonth() + 1; // Months start at 0!
          let dd = date.getDate();

          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;

          const formattedToday = dd + '/' + mm + '/' + yyyy;

          docs.unshift({ ...doc.data(), id: doc.id, body: body, date: formattedToday })

        })

        setLista(docs);
        console.log(lista1)
        docs.forEach(item => {
          const body = item.body
          //console.log(body.Titulacion__r.rio_ed__External_Id__c)
        })

      } catch (error) {
        console.log(error);
      }
    }
    getLista()
  }, [lista1]);



  // Funcion para eliminar

  const deleteDatos = async (id) => {
    await deleteDoc(doc(db, 'Errors', id))
  }


  const uploadErrorLeads = async (listaErrors) => {
    try {
      console.log(listaErrors)
      for (const i of listaErrors) {
        const docRef = doc(db, i.loc, i.id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log(docSnap.data());
          // Esperar 2 segundos (simular time.sleep(2))
          await new Promise(resolve => setTimeout(resolve, 2000));
          console.log('Esperado 5 segundos');
          uploadLead(docSnap.data(), i.loc, i.id);
        } else {
          console.log(`El documento con ID ${i.id} no existe.`);
        }
      }
    } catch (e) {
      console.log("Hubo un erroR")
    }
  }

  const uploadLead = async (docData, loc, idOld) => {
    const timestampStr = Date.now().toString();
    try {
      // Guardar el documento con el ID timestampStr en la colección "NG_Leads_NextGen"
      const docRef = doc(db, loc, timestampStr);
      await setDoc(docRef, docData); // Guarda el documento

      console.log('Documento guardado con éxito');
      await deleteDoc(doc(db, 'Errors', idOld))

    } catch (e) {
      console.error('Error al guardar el documento:', e);
    }
  };



  return (

    <>
      {
        lista1.length === 0 ? (
          <>
            <Container>
              <Row className="align-items-center mt-3">
                <Col lg={10}><h1>¡Todo va bien! Ningun error a la vista..</h1></Col>
              </Row>
            </Container>
            <Container className="d-flex justify-content-center mt-5"> <img src={imagen} alt="Descripción de la imagen" style={{ width: '600px' }} /></Container>
          </>
        ) : (
          lista1.map(list => (
            <>
              <Container>
                <Row className="align-items-center mt-3">
                  <Col lg={10}><h1>¡Algo va mal! Tenemos un cuack problema...</h1></Col>
                  <Col lg={2} className="d-flex justify-content-center">
                    <button className="btn btn-dark  " onClick={() => uploadErrorLeads(listaErrors)}>Upload Error Leads</button>
                  </Col>
                </Row>
                <Row><Col className="d-flex justify-content-center mt-5"></Col></Row>
              </Container>
              <Container key={list.id}>
                <Row >
                  <Col lg={10} className="bg-danger pt-3 rounded">
                    <Accordion defaultActiveKey="0" className="mb-3 ">
                      <Accordion.Item eventKey={list.id} >
                        <Accordion.Header >
                          <Row >
                            <Row >
                              <Col lg={4} className="d-flex align-items-center"><img src={imagen2} alt="cuack" style={{ width: '200px' }} /></Col>
                              <Col lg={8} >
                                <Row className="d-flex justify-content-between">
                                  <Col lg={4} ><strong>{list.id} | {list.date}</strong></Col>
                                  <Col lg={4} >{list.loc}</Col>
                                  <Col lg={4} >{list.error}</Col>
                                </Row>
                                <Row className=" mt-4">
                                  <Col lg={4}>{list.body.First_Name__c}</Col>
                                  <Col lg={4}>{list.body.Creation_DateTime__c}</Col>
                                  <Col lg={4}>{list.body.Titulacion__r.rio_ed__External_Id__c}</Col>
                                </Row>
                              </Col>
                            </Row>
                          </Row>
                        </Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <Col lg={6} >
                              <Row>
                                <Col lg={3} className="text-start"><strong>FirstName</strong></Col>
                                <Col lg={4} className="text-start">{list.body.First_Name__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>LastName</strong></Col>
                                <Col lg={4} className="text-start">{list.body.Last_Name__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Email</strong></Col>
                                <Col lg={4} className="text-start">{list.body.Email__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Nº </strong></Col>
                                <Col lg={4} className="text-start">{list.body.Numero_Documento__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Prefijo</strong></Col>
                                <Col lg={4} className="text-start">{list.body.Prefijo__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Nacionalidad</strong></Col>
                                <Col lg={4} className="text-start">{list.body.Nacionalidad__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Birthdate</strong></Col>
                                <Col lg={4} className="text-start">{list.body.Birthdate__c}</Col>
                              </Row>
                            </Col>
                            <Col>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Telefono</strong></Col>
                                <Col lg={4} className="text-start">{list.body.Mobile_Phone__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Tipo Documento</strong></Col>
                                <Col lg={4} className="text-start">{list.body.Tipo_Documento__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Portfolio</strong></Col>
                                <Col lg={4} className="text-start">{list.body.Portfolio__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Statement</strong></Col>
                                <Col className="text-start">{list.body.Statement__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Videopich</strong></Col>
                                <Col className="text-start">{list.body.Videopich__c}</Col>
                              </Row>
                              <Row>
                                <Col lg={3} className="text-start"><strong>Curriculum</strong></Col>
                                <Col className="text-start">{list.body.Curriculum__c}</Col>
                              </Row>

                            </Col>

                          </Row>

                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  <Col lg={2} className="text-start d-flex align-items-center"><button className="btn btn-danger mx-auto d-block rounded align-items-center p-5 shadow p-3 mb-5" onClick={() => deleteDatos(list.id)}>DELETE</button></Col>
                </Row>
              </Container>
            </>
          ))
        )
      }
    </>
  )
}

export default Show