import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebaseConfig/firebase';
import { signOut } from "firebase/auth";

import Show from './Show';
import Login from './Login';

const Home = () => {

    const navigate = useNavigate();
    const [valido, setValido] = useState();

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Sign-out successful.
            navigate("/Login");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                const uid = user.uid;
                console.log("uid", uid)
                console.log(user);
                setValido(true);

            } else {
                setValido(false);
                console.log("user is logged out")

            }
        });

    }, [])

    if (valido) return (
        <>
            <div className="bg-dark">
                <button className="btn btn-primary m-3 bg-dark" onClick={handleLogout}>
                    Logout
                </button>
            </div>
            <Show />
        </>
    )

    return (

        <Login />

    )
}

export default Home